<template>
  <div>
    <div class="remark">
      <p>操作指南：</p>
      <p>1. 请先下载
        <el-link type="primary" :href="templateUrl">Excel模板</el-link>
      </p>
      <p>2. 按模板文件格式填写数据</p>
      <p>3. 确认文件内容无误后，上传文件</p>

      <el-upload class="upload-demo"
                 drag
                 :headers="uploadHeaders"
                 :on-success="uploadSuccess"
                 :on-progress="uploadProgress"
                 :action="uploadFullUrl">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
        <p slot="tip" v-if="loading">上传中</p>
        <p class="loadingThree" slot="tip" v-if="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </p>
        <p slot="tip" v-if="loadSuccess" class="success">{{ success }}</p>
        <p slot="tip" v-if="loadError" class="error">{{ success }}</p>
      </el-upload>

    </div>
  </div>
</template>

<script>
export default {
  name: "ImportComponent",
  props: {
    templateUrl: {
      type: String,
      required: true
    },
    uploadUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      baseUrl: '',
      uploadFullUrl: '',
      uploadHeaders: {},
      loading: false,
      success: '',
      loadSuccess: false,
      loadError: false
    }
  },
  methods: {
    uploadSuccess: function (response) {
      if (response.code == 0) {
        this.success = response.message;
        this.loadSuccess = true;
      } else {
        this.success = response.message;
        this.loadError = true;
      }

      this.loading = false;
    },
    uploadProgress: function () {
      this.loadSuccess = false;
      this.loadError = false;
      this.loading = true;
    },
  },
  created() {
    //重要设置
    this.uploadFullUrl = this.$baseUrl + this.uploadUrl;
    this.uploadHeaders = {token: localStorage.getItem('token')};

  }
}
</script>

<style scoped>

.remark {
  line-height: 30px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  color: darkslategray;
}

p {
  margin: 0;
}

.success {
  color: green;
  font-size: 18px;
  padding-right: 10px;
}

.error {
  color: red;
  font-size: 18px;
  padding-right: 10px;
}

.loadingThree {
  width: 150px;
  height: 15px;
}

.loadingThree span {
  display: inline-block;
  width: 15px;
  height: 100%;
  margin-right: 5px;
  border-radius: 50%;
  background: deepskyblue;
  -webkit-animation: load 1.04s ease infinite;
}

.loadingThree span:last-child {
  margin-right: 0px;
}

@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loadingThree span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}

.loadingThree span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}

.loadingThree span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}

.loadingThree span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}

.loadingThree span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
</style>